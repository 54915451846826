import { contentConfiguration } from "../../content";
import { Column, Row, WrapperLarge } from "../../layout/website";
import Page from "../../pages/website/Page";
import { css } from "@emotion/react";
import { SmallHeadline } from "@minted/fancyclothes";
import { styleUtils } from "@minted/minted-components";
import PropTypes from "prop-types";
import React, { Component } from "react";

const marginBottomStyles = css`
  margin-bottom: ${styleUtils.rem(20)};
`;

class EmptyPage extends Component {
  render() {
    const { children, configuration, headline, id, title } = this.props;

    return (
      <Page id={id} title={title}>
        <WrapperLarge>
          <Row>
            <Column>
              <div
                css={contentConfiguration.textAlignmentMap.get(
                  configuration.textAlignment || "center",
                )}
                data-cy="emptyPage"
              >
                <div css={marginBottomStyles} data-cy="subheading">
                  <SmallHeadline>{headline}</SmallHeadline>
                </div>
                <div data-cy="body">{children}</div>
              </div>
            </Column>
          </Row>
        </WrapperLarge>
      </Page>
    );
  }
}

EmptyPage.propTypes = {
  children: PropTypes.node.isRequired,
  configuration: contentConfiguration.configurationPropTypes,
  headline: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default EmptyPage;
