import PageTitle from "./PageTitle";
import { css } from "@emotion/react";
import { LargeDivider } from "@minted/fancyclothes";
import { styleUtils } from "@minted/minted-components";
import PropTypes from "prop-types";
import React from "react";

const centeredTextStyles = css`
  text-align: center;
`;

const marginBottomStyles = css`
  margin-bottom: ${styleUtils.rem(80)};
`;

const paddingTopStyles = css`
  padding-top: ${styleUtils.rem(80)};
`;

const Page = ({ children, id, removeSpacing = false, title }) => (
  <div css={!removeSpacing && paddingTopStyles} id={id}>
    <div css={centeredTextStyles}>
      {!!title && (
        <PageTitle hideDivider={id === "registry"}>{title}</PageTitle>
      )}
    </div>
    <div css={!removeSpacing && marginBottomStyles}>{children}</div>
    <LargeDivider />
  </div>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Page;
