import createMemoizer from "../../core/dashboard/createMemoizer";
import { buildRouteArgs } from "../../pages/utils";
import Paragraphs from "../../pages/website/Paragraphs";
import RefSource from "../../ref-monitor/RefSource";
import { isRouteActive } from "../router.js";
import { selectAsset, selectSite } from "../selectors";
import AnnouncementBanner from "./AnnouncementBanner";
import FancyClothesContext from "./FancyClothesContext";
import { css } from "@emotion/react";
import {
  LargeDivider,
  LargeGraphic,
  LargeHeadline,
  MobileLargeGraphic,
  WeddingHomeHorizontalFullHeader,
  WeddingHomeVerticalDoubleHeader,
  WeddingHomeVerticalHeader,
  WeddingHomeVerticalSingleHeader,
  withDesign,
} from "@minted/fancyclothes";
import {
  styleUtils,
  theme,
  useMediaQueryState,
} from "@minted/minted-components";
import { parse as parseDate, format as formatDate } from "date-fns";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "redux-router5";

const headerStyles = {
  centered: css`
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4};
    margin-right: auto;
    margin-left: auto;
    padding-left: ${theme.spacing.x4};
    padding-right: ${theme.spacing.x4};
    max-width: ${styleUtils.rem(780)};
  `,
  fullWidth: css`
    width: 15%;
    align-self: center;
  `,
};

const couplePhotoLargeDefault = require("../images/website/photo-couple-wide.png");
const couplePhotoDefault = require("../images/website/photo-couple.png");
const singlePhotoDefault = require("../images/website/photo-single.png");

const buildNavItems = (
  pages,
  router,
  transitionTo,
  navigateTo,
  isCustomizePage,
) =>
  pages.map((page) => ({
    active: isRouteActive({
      isCustomizePage,
      router,
      slug: page.slug,
    }),
    onClick: (event) => {
      event.preventDefault();
      transitionTo(page.slug);

      if (navigateTo) {
        const { name, params } = buildRouteArgs({
          data: page,
        });

        navigateTo(name, params);
      }
    },
    title: page.givenName,
    url: router.buildUrl(page.slug),
  }));

const renderHeader = (
  isMobile,
  site,
  assets,
  navItems,
  children,
  condensed,
  textAlignment,
) => {
  if (site.isWedding) {
    let weddingDate;
    const eventDateHeading =
      site.eventDateHeading || "Please join us for our wedding celebration on";

    if (site.weddingDate) {
      // change format to what fancyclothes requires
      try {
        const parsedDate = parseDate(
          site.weddingDate,
          "MM/dd/yyyy",
          new Date(),
        ); // Using new Date(site.weddingDate) was off by one day

        weddingDate = formatDate(parsedDate, "yyyy-MM-dd");
      } catch (RangeError) {
        weddingDate = `${new Date().getFullYear() + 1}-08-01`;
      }
    } else {
      weddingDate = `${new Date().getFullYear() + 1}-08-01`;
    }
    const commonProps = {
      bottomName: site.spouseName || "Name",
      condensed,
      countdownText: site.countdownText || "the big day",
      eventDateHeading,
      introHeading: site.welcomeHeading || "Our Story",
      isCountdownVisible: site.isCountdownVisible,
      isWeddingDateVisible: site.isWeddingDateVisible,
      leftName: site.yourName || "Name",
      navItems,
      rightName: site.spouseName || "Name",
      textAlignment,
      topName: site.yourName || "Name",
      weddingDate,
    };

    switch (site.themeIdentifier) {
      case "l1":
        return (
          <WeddingHomeVerticalSingleHeader
            photoURL={assets.themeImage || couplePhotoDefault}
            {...commonProps}
          >
            {children}
          </WeddingHomeVerticalSingleHeader>
        );
      case "l2":
        return (
          <WeddingHomeVerticalDoubleHeader
            leftPhotoURL={assets.brideImage || singlePhotoDefault}
            rightPhotoURL={assets.groomImage || singlePhotoDefault}
            {...commonProps}
            eventDateHeading={eventDateHeading + " "} // Needs space for inline heading + date
          >
            {children}
          </WeddingHomeVerticalDoubleHeader>
        );
      case "l3":
        return (
          <WeddingHomeHorizontalFullHeader
            photoURL={assets.themeImage || couplePhotoLargeDefault}
            {...commonProps}
          >
            {children}
          </WeddingHomeHorizontalFullHeader>
        );
      case "l4":
        return (
          <WeddingHomeVerticalHeader {...commonProps}>
            {children}
          </WeddingHomeVerticalHeader>
        );
    }
  } else if (site.isOnlineInvitation) {
    return (
      <div>
        {isMobile ? (
          <MobileLargeGraphic text="Online invitation header image" />
        ) : (
          <LargeGraphic text="Online invitation header image" />
        )}
        <div css={headerStyles.centered}>
          <LargeHeadline>{site.eventHeading || ""}</LargeHeadline>
          <div css={headerStyles.fullWidth}>
            <hr />
          </div>
          <Paragraphs
            text={site.eventDescription || ""}
            textAlignment="center"
          />
        </div>
      </div>
    );
  }
};

@withDesign("HeaderDivider")
class HeaderDivider extends Component {
  render() {
    const { getRule, isMultiPage } = this.props;
    const introBackgroundColor = getRule("backgroundColor", "IntroContainer");
    const introBackgroundPattern = getRule(
      "backgroundPattern",
      "IntroContainer",
    );
    const hasNoIntroBackground = !(
      introBackgroundColor || introBackgroundPattern
    );
    const shouldHaveSeparator = isMultiPage || hasNoIntroBackground;

    return shouldHaveSeparator && <LargeDivider />;
  }
}

const WebsiteHeader = (
  {
    assets,
    children,
    condensed,
    connectRef,
    isCustomizePage,
    navigateTo,
    pages,
    site,
    textAlignment = "center",
  },
  { router, transitionTo },
) => {
  const mediumMediaQueryState = useMediaQueryState({
    mediaQuerySize: "small",
  });
  const isMobile = mediumMediaQueryState === "BELOW";
  const navItems = buildNavItems(
    pages,
    router,
    transitionTo,
    navigateTo,
    isCustomizePage,
  );

  return connectRef(
    "home",
    "pages",
    <div>
      {site.showAnnouncement && (
        <AnnouncementBanner
          bodyText={site.announcementBody}
          headingText={site.announcementHeading}
        />
      )}

      <FancyClothesContext.Consumer>
        {() => (
          <div>
            {renderHeader(
              isMobile,
              site,
              assets,
              navItems,
              children,
              condensed,
              textAlignment,
            )}

            <HeaderDivider
              isMultiPage={site.pageConfiguration === "multi_page"}
            />
          </div>
        )}
      </FancyClothesContext.Consumer>
    </div>,
  );
};

WebsiteHeader.propTypes = {
  isCustomizePage: PropTypes.bool,
};

WebsiteHeader.contextTypes = {
  router: PropTypes.object.isRequired,
  transitionTo: PropTypes.func.isRequired,
};

const memoizeAssets = createMemoizer();
const memoizeSite = createMemoizer();

const mapStateToProps = (state, props) => {
  const site = memoizeSite(selectSite(state.resources.sites));

  const assets = {};

  if (site.isWedding) {
    switch (site.themeIdentifier) {
      case "l1":
        assets.themeImage = selectAsset(
          state.resources,
          "themeImage",
          "crop1200650",
        );
        break;
      case "l2":
        assets.brideImage = selectAsset(
          state.resources,
          "brideImage",
          "crop500500",
        );
        assets.groomImage = selectAsset(
          state.resources,
          "groomImage",
          "crop500500",
        );
        break;
      case "l3":
        assets.themeImage = selectAsset(
          state.resources,
          "themeImage",
          "crop16001067",
        );
        break;
      case "l4":
        break; // no images
    }
  }

  return {
    assets: memoizeAssets(assets),
    children: props.children,
    condensed: props.condensed,
    pages: props.pages,
    site,
  };
};

const bindActions = (dispatch) => ({
  dispatch,
  navigateTo: bindActionCreators(actions.navigateTo, dispatch),
});

export default connect(
  mapStateToProps,
  bindActions,
)(RefSource(WebsiteHeader, "home"));
