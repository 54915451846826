import { DeepLinkContext } from "../../../contexts/DeepLinkContext";
import API from "../../../core/api";
import RSVPForm from "../../../guest-management/forms/RSVPForm/RSVPForm";
import RSVPSearchModal from "../../../guest-management/forms/RSVPSearchModal/RSVPSearchModal";
import { getGuestTokenCookie } from "../../actions";
import { css } from "@emotion/react";
import {
  FloatingActionButton,
  Modal,
  ModalBody,
  ModalHeader,
  styleUtils,
  theme,
} from "@minted/minted-components";
import React, { useCallback, useContext, useEffect, useState } from "react";

const rsvpStyles = {
  buttonWrapper: css`
    margin-left: ${theme.spacing.x4};
    margin-right: ${theme.spacing.x4};
    position: fixed;
    bottom: ${theme.spacing.x5};
    z-index: 1000;
    button {
      width: ${styleUtils.rem(112)};
    }
    ${theme.media.greaterThan("large")`
      button {
        width: ${styleUtils.rem(200)};
        font-size: 24px;
      }
    `};
  `,
  modalHeaderText: css`
    color: ${theme.colors.black};
  `,
};

const OnlineInvitationRSVP = ({ site }) => {
  const [isRSVPFormOpen, setIsRSVPFormOpen] = useState(false);
  const [isRSVPSearchFormOpen, setIsRSVPSearchFormOpen] = useState(false);
  const [rsvpData, setRSVPData] = useState(null);

  const { addressbookContactId } = useContext(DeepLinkContext);

  // Load RSVP form data if deep link is provided
  useEffect(() => {
    if (addressbookContactId) {
      const guestToken = getGuestTokenCookie();
      const headers = {
        Authorization: `Guest ${guestToken}`,
      };

      API.get(
        "guest-management/rsvp-form-data",
        {
          headers,
        },
        {
          addressbook_contact_id: addressbookContactId,
        },
      ).then((responseData) => {
        if (responseData.contact) {
          // For OI, event title comes from site details
          responseData.events[0].title = site.eventHeading;
          setRSVPData(responseData);
        }
      });
    }
  }, [addressbookContactId, setRSVPData, setIsRSVPFormOpen, site.eventHeading]);

  const setRsvpModalData = useCallback((rsvpData) => {
    setRSVPData(rsvpData);
    setIsRSVPSearchFormOpen(false);
    setIsRSVPFormOpen(true);
  }, []);

  return (
    <>
      <div css={rsvpStyles.buttonWrapper}>
        <FloatingActionButton
          onClick={() => {
            if (addressbookContactId && rsvpData) {
              setIsRSVPFormOpen(true);
            } else {
              setIsRSVPSearchFormOpen(true);
            }
          }}
          text="RSVP"
        />
      </div>
      {isRSVPSearchFormOpen && (
        <RSVPSearchModal
          closeModal={() => setIsRSVPSearchFormOpen(false)}
          setRsvpData={setRsvpModalData}
          site={site}
        />
      )}

      {isRSVPFormOpen && rsvpData && (
        <Modal
          mobileFullscreen
          onCloseClick={() => setIsRSVPFormOpen(false)}
          open
          size="medium"
        >
          <ModalHeader>
            <div css={rsvpStyles.modalHeaderText}>RSVP</div>
          </ModalHeader>
          <ModalBody>
            <RSVPForm
              contact={rsvpData.contact}
              events={rsvpData.events}
              guests={rsvpData.guests}
              site={site}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default OnlineInvitationRSVP;
