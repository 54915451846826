import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // component to display if an error is triggered
  fallbackComponent: PropTypes.object,
};

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);

    typeof window !== "undefined" &&
      window.newrelic &&
      window.newrelic.noticeError(error);

    this.setState({
      hasError: true,
    });
  }

  render() {
    const { children } = this.props;

    const { hasError } = this.state;

    return hasError ? (
      this.props.fallbackComponent ? (
        this.props.fallbackComponent
      ) : (
        <div>
          <p>
            Minted will be back shortly. We are working hard to make our site
            available to you as soon as possible. Please check back in a few
            minutes. Have a question? Contact us at 1.888.828.6468 or
            help@minted.com.
          </p>
        </div>
      )
    ) : (
      children
    );
  }
}

ErrorBoundary.propTypes = propTypes;

export default ErrorBoundary;
